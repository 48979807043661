import React, { useEffect, useState } from "react";
import "./teenpatti.scoped.css";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { CASINO_VIDEO_URL, getProfit } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { casinoBetAPI } from "../../service/AuthService";
import { Modal } from "react-bootstrap";
import Timer from "../components/Timer";
export default function TeenPatti() {
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [betTimer, setBetTimer] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [exposureData, setExposureData] = useState([]);
  const [showRules, setShowRules] = React.useState(false);
  const game = "casino";
  const GAME_ID = "TEEN_20";

  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const startBetTimer = () => {
    let timer = 7;
    setBetTimer(timer);
    (function() {
      var y = setInterval(function() {
        timer = timer - 1;
        setBetTimer(timer);
        if (timer == 0) {
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
          clearInterval(y);
        }
      }, 1000);
    })();
  };

  const handleDefaultValueClick = (value, toAppend = false) => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...selectedEventForBet,
        amount: toAppend
          ? String(
              selectedEventForBet?.amount ? selectedEventForBet.amount : ""
            ) + String(value)
          : value,
        profit: getProfit(value, game, selectedEventForBet).profit,
        loss: getProfit(value, game, selectedEventForBet).loss,
      },
    });
  };
  const placeBet = async () => {
    let delay = 1500;
    setIsLoading(true);
    if (selectedEventForBet?.amount <= 0) {
      toast.error("Please enter a Valid Amount");
      setIsLoading(false);
      return;
    }
    if (selectedEventForBet?.amount > 25000) {
      toast.error("Max bet amount is 25000");
      return;
    }
    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedEventForBet.selectedOdd <= 0) {
      toast.error("Please select a Valid Odd");
      setIsLoading(false);
      return;
    }
    let payload = {
      amount: selectedAmount_,
      marketId: selectedEventForBet?.marketId,
      profit: getProfit(selectedAmount_, game, selectedEventForBet)?.profit,
      loss: getProfit(selectedAmount_, game, selectedEventForBet)?.loss,
      runnerName: selectedEventForBet?.runnerName,
      selectionId: selectedEventForBet?.selectedId,
      casinoGames: GAME_ID,
      back: selectedEventForBet?.selectedOdd,
      type: selectedEventForBet?.type,
    };
    let { data, response, code } = await casinoBetAPI(payload);
    if (code == 200) {
      toast.success("Bet Placed");
    } else if (code == 500) {
      toast.error(response);
    } else if (code == "ERR_BAD_RESPONSE") {
      toast.error("Something Went Wrong");
    } else {
      toast.error("Something Went Wrong");
    }

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  };
  const setBet = (data, type = "back") => {
    let matchData = casinoData?.data?.response;
    if (!matchData) return;
    startBetTimer();
    let selectedEventForBet = {
      amount: 0,
      marketId: matchData?.marketId,
      type: type?.startsWith("lay") ? "lay" : "back",
      selectedOdd: data?.back1,
      selectedId: data.selectionId,
      profit: getProfit(0, game, {
        selectedOdd: data?.back1,
      }).profit,
      loss: getProfit(0, game, {
        selectedOdd: data?.back1,
      }).loss,
      amount: null,
      runnerName: data.runnerName,
      isYesAndNo: false,
    };
    if (selectedEventForBet?.selectedOdd == 0) {
      return;
    }
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: selectedEventForBet,
    });
  };
  const getExposureData = async (marketId) => {
    try {
      const { response } = await getCasinoExposureAPI(marketId);
      setExposureData(response);
    } catch {
      console.log("MARKET");
    }
  };

  const getExposure = (marketId, runnerName) => {
    const market = exposureData?.find((item) => item?.marketId == marketId)
      ?.casinoPLExposure;

    for (let i = 0; i < market?.length; i++) {
      if (market[i].selectionName == runnerName) {
        return market[i].exposure;
      }
    }
  };
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  useEffect(() => {
    let interval = setInterval(() => {
      getExposureData(casinoData?.data?.response?.marketId);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [casinoData?.data?.response?.marketId]);
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const getOddByRunnerName = (runnerName) => {
    return casinoData?.data?.response?.oddDetailsDTOS?.find(
      (item) => item?.runnerName?.toLowerCase() == runnerName?.toLowerCase()
    )?.back1;
  };
  return (
    <div
      className="container-fluid p-0 ng-tns-c156-2 snipcss-IA2wY style-EXRQr"
      id="style-EXRQr"
    >
      {showRules && (
        <div
          role="dialog"
          aria-modal="true"
          className="fade InfoModal modal show"
          tabIndex={-1}
          aria-labelledby="contained-modal-title-vcenter"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modalHeader w-100 modal-header">
                <div className="row justify-content-between w-100 mx-0">
                  <div className="headerText">20-20 Teenpatti Rules</div>

                  <div
                    onClick={() => setShowRules(false)}
                    className="closeModal"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="modalBody modal-body">
                <img src={require("../../assets/teenpatti-rules.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedEventForBet && (
        <Modal
          show={selectedEventForBet}
          centered
          className="bet-modal"
          onClose={() => {
            dispatch({
              type: "gameData/setSelectedEventForBet",
              payload: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="">
            <div className="modal-content">
              <a
                className="close-modal"
                onClick={() => {
                  dispatch({
                    type: "gameData/setSelectedEventForBet",
                    payload: null,
                  });
                }}
              >
                Cancel
              </a>
              <div className="modal-body">
                <div className="container">
                  <div className="bet-strip row">
                    <div className="col-6">
                      <label>Team</label>
                      <p>{selectedEventForBet?.runnerName}</p>
                    </div>
                    <div className="col-3">
                      <label>Rate</label>
                      <p>{selectedEventForBet?.selectedOdd}</p>
                    </div>
                    <div className="col-3">
                      <label>Mode</label>
                      <p>
                        {" "}
                        {selectedEventForBet?.isFancy
                          ? selectedEventForBet.type == "back"
                            ? "YES"
                            : "NO"
                          : selectedEventForBet?.type != "back"
                          ? "Khai"
                          : "Lagai"}
                      </p>
                    </div>
                  </div>
                  <div className="my-3 row">
                    <div className="bet-price-btns col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(100)}
                      >
                        100
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(500)}
                      >
                        500
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(1000)}
                      >
                        1000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(2000)}
                      >
                        2000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(5000)}
                      >
                        5000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(10000)}
                      >
                        10000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(20000)}
                      >
                        20000
                      </button>
                      {/*   <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(50000)}
                      >
                        50000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(100000)}
                      >
                        100000
                      </button> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3 input-group">
                        <input
                          placeholder="Amount"
                          value={selectedEventForBet?.amount}
                          onChange={(e) => {
                            dispatch({
                              type: "gameData/setSelectedEventForBet",
                              payload: {
                                ...selectedEventForBet,
                                amount: e.target.value,
                                profit: getProfit(
                                  e.target.value,
                                  game,
                                  selectedEventForBet
                                ).profit,
                                loss: getProfit(
                                  e.target.value,
                                  game,
                                  selectedEventForBet
                                ).loss,
                              },
                            });
                          }}
                          type="Number"
                          className="form-control"
                        />
                        <span
                          className="bg-danger text-white input-group-text"
                          id="basic-addon3"
                        >
                          <p>{betTimer}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0 modal-footer">
                <button
                  type="button"
                  className="w-100 m-0 btn btn-primary"
                  onClick={placeBet}
                >
                  Placebet
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="row ng-tns-c156-2">
        <div className="col-md-12 featured-box-detail casino-table-wrapper mb-2 ng-tns-c156-2">
          <div className="row ng-tns-c156-2">
            <div className="col-md-8 ng-tns-c156-2">
              <div className="markets ng-tns-c156-2">
                <div className="casino-heading ng-tns-c156-2">
                  <span className="card-header-title ng-tns-c156-2">
                    20-20 LIVE TEENPATTI
                  </span>
                  <span className="ng-tns-c156-2">
                    Round ID: {casinoData?.data?.response?.marketId}
                    <svg
                      class="cursor-pointer"
                      stroke="currentColor"
                      onClick={() => setShowRules(true)}
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm19 304h-38.2V207.9H275V352zm-19.1-159.8c-11.3 0-20.5-8.6-20.5-20s9.3-19.9 20.5-19.9c11.4 0 20.7 8.5 20.7 19.9s-9.3 20-20.7 20z" />
                    </svg>
                  </span>
                </div>
                <div className="score-panel ng-tns-c156-2">
                  <div className="showcards-container ng-tns-c156-2">
                    <div className="showcards-wrapper ng-tns-c156-2 ng-star-inserted">
                      <div className="top-left-team margin-minus ng-tns-c156-2">
                        <span
                          className="ng-tns-c156-2 style-Qwzza"
                          id="style-Qwzza"
                        >
                          Player A
                        </span>
                      </div>
                      <div className="top-leftTeen margin-minus ng-tns-c156-2">
                        {casinoData?.data?.response?.cards[0]?.map((item) => (
                          <img
                            width={28}
                            className="img-fluid me-2 ng-tns-c156-2 ng-trigger ng-trigger-flipState style-4PDyE"
                            src={`https://1exch.net/assets/imgages/cardLive/${item}.png`}
                            id="style-4PDyE"
                          />
                        ))}
                      </div>
                      <div className="top-left-team1 margin-minus ng-tns-c156-2">
                        <span
                          className="ng-tns-c156-2 style-vmgQk"
                          id="style-vmgQk"
                        >
                          Player B
                        </span>
                      </div>
                      <div className="top-left11Teen margin-minus ng-tns-c156-2">
                        {casinoData?.data?.response?.cards[1]?.map((item) => (
                          <img
                            width={28}
                            className="img-fluid me-2 ng-tns-c156-2 ng-trigger ng-trigger-flipState style-4PDyE"
                            src={`https://1exch.net/assets/imgages/cardLive/${item}.png`}
                            id="style-4PDyE"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="casino-counter ng-tns-c156-2">
                    {casinoData?.data?.response?.autoTime != "" && (
                      <Timer value={casinoData?.data?.response?.autoTime} />
                    )}
                  </div>
                  <iframe
                    width="100%"
                    height={250}
                    className="ng-tns-c156-2 ng-star-inserted style-le5Kd"
                    src={CASINO_VIDEO_URL + "3030"}
                    id="style-le5Kd"
                  />
                </div>
              </div>
              <div className="session-market ng-tns-c156-2">
                <div className="form-row ng-tns-c156-2">
                  <div className="col-sm-12 ng-tns-c156-2">
                    <div className="table-header ng-tns-c156-2">
                      <div className="country-name box-6 ng-tns-c156-2">
                        <span className="ng-tns-c156-2">
                          Min: 100 | Max: 25000
                        </span>
                      </div>
                      <div className="back box-4 text-center ng-tns-c156-2">
                        <b className="ng-tns-c156-2">BACK</b>
                      </div>
                    </div>
                    <div className="table-body ng-tns-c156-2">
                      <div className="table-row ng-tns-c156-2">
                        <div className="country-name box-6 ng-tns-c156-2">
                          <span className="team-name ng-tns-c156-2">
                            Player A
                            <span
                              style={{
                                marginLeft: "10px",
                                color:
                                  getExposure(
                                    casinoData?.data?.response?.marketId,
                                    "Player A"
                                  ) < 0
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {getExposure(
                                casinoData?.data?.response?.marketId,
                                "Player A"
                              )}
                            </span>
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              casinoData?.data?.response?.oddDetailsDTOS?.find(
                                (item) =>
                                  item?.runnerName?.toLowerCase() ==
                                  "Player A".toLowerCase()
                              )
                            )
                          }
                          className={`box-4 back ${
                            parseInt(casinoData?.data?.response?.autoTime) < 5
                              ? "disabled suspended-casino"
                              : ""
                          } text-center pointer  ng-tns-c156-2`}
                        >
                          <span className="odd d-block ng-tns-c156-2">
                            {getOddByRunnerName("Player A")}
                          </span>
                          <span
                            className="success ng-tns-c156-2 style-ahq2n"
                            id="style-ahq2n"
                          >
                            0
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="table-body ng-tns-c156-2">
                      <div className="table-row ng-tns-c156-2">
                        <div className="country-name box-6 ng-tns-c156-2">
                          <span className="team-name ng-tns-c156-2">
                            Player B
                            <span
                              style={{
                                marginLeft: "10px",
                                color:
                                  getExposure(
                                    casinoData?.data?.response?.marketId,
                                    "Player B"
                                  ) < 0
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {getExposure(
                                casinoData?.data?.response?.marketId,
                                "Player B"
                              )}
                            </span>
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            setBet(
                              casinoData?.data?.response?.oddDetailsDTOS?.find(
                                (item) =>
                                  item?.runnerName?.toLowerCase() ==
                                  "Player B".toLowerCase()
                              )
                            )
                          }
                          className={`box-4 back ${
                            parseInt(casinoData?.data?.response?.autoTime) < 5
                              ? "disabled suspended-casino"
                              : ""
                          } text-center pointer  ng-tns-c156-2`}
                        >
                          <span className="odd d-block ng-tns-c156-2">
                            {getOddByRunnerName("Player B")}
                          </span>
                          <span
                            className="success ng-tns-c156-2 style-Vi7Mv"
                            id="style-Vi7Mv"
                          >
                            0
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="results-container ng-tns-c156-2">
                <div className="casino-title mt-1 ng-tns-c156-2">
                  Last Result
                  <a
                    href="javascript:void(0)"
                    className="m-r-5 game-rules-icon ng-tns-c156-2"
                  >
                    <span className="ng-tns-c156-2">View All</span>
                  </a>
                </div>
                <div
                  align="right"
                  className="ng-tns-c156-2 style-mVTSZ"
                  id="style-mVTSZ"
                >
                  <p className="text-right ball-by-ball mt-2 ng-tns-c156-2">
                    {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                      (item) => (
                        <span
                          className={`ng-tns-c156-2 cards-done ${
                            item?.mainWinner == "B" ? "four" : ""
                          } ml-1 pointer ng-star-inserted style-LDzmS`}
                          id="style-LDzmS"
                        >
                          {item?.mainWinner}
                        </span>
                      )
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 pl-0 ng-tns-c156-2">
              <div
                style={{ marginRight: "0px" }}
                className="accordian-view ng-tns-c156-2"
              >
                <div className="card my-bet ng-tns-c156-2">
                  <div className="card-header ng-tns-c156-2">
                    <div className="w-100 ng-tns-c156-2">
                      <h5 className="m-0 ng-tns-c156-2">MY BET</h5>
                    </div>
                  </div>
                  <div className="card-body ng-tns-c156-2">
                    <div className="personal-info-content ng-tns-c156-2">
                      <table className="w-100 ng-tns-c156-2">
                        <thead className="ng-tns-c156-2">
                          <tr className="ng-tns-c156-2">
                            <th className="ng-tns-c156-2">Matched Bet</th>
                            <th className="ng-tns-c156-2">Market</th>
                            <th className="ng-tns-c156-2">Odds</th>
                            <th className="ng-tns-c156-2">Stake</th>
                          </tr>
                        </thead>
                        {casinoBets?.data?.response?.map((item) => (
                          <thead className="ng-tns-c156-2">
                            <tr className="ng-tns-c156-2">
                              <th className="ng-tns-c156-2">
                                {item?.casinoGames}
                              </th>
                              <th className="ng-tns-c156-2">
                                {item?.runnerName}
                              </th>
                              <th className="ng-tns-c156-2">{item?.back}</th>
                              <th className="ng-tns-c156-2">{item?.amount}</th>
                            </tr>
                          </thead>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  align="center"
                  className="justify-content-center ng-tns-c156-2 style-DAmMM"
                  id="style-DAmMM"
                >
                  <button
                    onClick={() => {
                      window.location.href = "/casino/casino-bets";
                    }}
                    className="btn btn-dark ng-tns-c156-2"
                    tabIndex={0}
                  >
                    See All Complete Bets
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
