import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./matka.scoped.css";
import { useSelector } from "react-redux";
import {
  getMatkaBetsAPI,
  getMatkaMarketAPI,
  placeBetMatka,
} from "../../service/matka";
import moment from "moment";
import toast from "react-hot-toast";
export default function MatkaPlay() {
  const [type, setType] = React.useState("JODI");
  const location = useLocation();
  const params = useParams();
  const game = params?.id;
  const userData = useSelector((state) => state.account.userData);
  const [matkaGames, setMatkaGames] = React.useState([]);
  const [currentGame, setCurrentGame] = React.useState({});
  const [myBets, setMyBets] = React.useState([]);
  const getGames = async () => {
    const { response } = await getMatkaMarketAPI();
    setMatkaGames(response);
    setCurrentGame(response.find((item) => item.id == game));
  };
  const getMyBets = async () => {
    const { response } = await getMatkaBetsAPI(game);
    setMyBets(response);
  };
  useEffect(() => {
   let interval = setInterval(() => {
     getMyBets();
   }, 5000);

   getMyBets();
   getGames();
   return () => {
     clearInterval(interval);
   };
  }, []);
  const INITIAL_VALUES = {
    JODI: Array.from({ length: 100 }, (_, i) => i).map((item) => {
      return {
        number: item,
        value: "",
      };
    }),
    HARUF: {
      ANDAR: Array.from({ length: 10 }, (_, i) => i).map((item) => {
        return {
          number: item,
          value: "",
        };
      }),
      BAHAR: Array.from({ length: 10 }, (_, i) => i).map((item) => {
        return {
          number: item,
          value: "",
        };
      }),
    },
  };
  const [payload, setPayload] = React.useState(INITIAL_VALUES);
  const placeBet = async (market) => {
    let arr = [];
    if (type == "JODI") {
      for (let i = 0; i < 100; i++) {
        if (payload[type][i].value) {
          arr.push({
            number: payload[type][i].number,
            amount: payload[type][i].value,
          });
        }
      }
    } else {
      if (market == "ANDAR_") {
        for (let i = 0; i < 10; i++) {
          if (payload[type].ANDAR[i].value) {
            arr.push({
              number: payload[type].ANDAR[i].number,
              amount: payload[type].ANDAR[i].value,
            });
          }
        }
      }
      if (market == "BAHAR_") {
        for (let i = 0; i < 10; i++) {
          if (payload[type].BAHAR[i].value) {
            arr.push({
              number: payload[type].BAHAR[i].number,
              amount: payload[type].BAHAR[i].value,
            });
          }
        }
      }
    }

    const { response, code, status } = await placeBetMatka({
      betLockNumberAndAmountDTOList: arr,
      betType: market + type,
      marketId: currentGame.id,
    });

    if (code === 200) {
      toast.success(status);
      setPayload(INITIAL_VALUES);
    } else {
      toast.error(response);
    }
  };

  return (
    <div className="gameplay">
      <div className="top-bar-container">
        <ul className="top-bar">
          <li
            onClick={() => {
              setType("JODI");
            }}
            className={type == "JODI" ? "active" : ""}
          >
            Jodi
          </li>

          <li
            className={type == "HARUF" ? "active" : ""}
            onClick={() => {
              setType("HARUF");
            }}
          >
            Haruf
          </li>
        </ul>
      </div>
      <div className="counter-game">
        {currentGame?.matka?.name} game Ends in{" "}
        <span>{currentGame?.matka?.closeTime}</span>
      </div>
      {type == "JODI" ? (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pointer">
                <div>
                  <p className="header">Points Remaining</p>
                  <p className="point-value">
                    {userData?.balance -
                      payload[type]?.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0)}
                  </p>
                </div>
                <div>
                  <p className="header">Points Added</p>
                  <p className="point-value">
                    {payload[type]?.reduce((acc, item) => {
                      if (item.value) {
                        return acc + parseInt(item.value);
                      }
                      return acc;
                    }, 0)}
                  </p>
                </div>
              </div>
              <div className="jodi">
                <div className="main-content">
                  {payload[type]?.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10
                          ? "0" + item?.number
                          : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: payload[type].map((i) => {
                              if (i.number == item.number) {
                                return {
                                  ...i,
                                  value: e.target.value,
                                };
                              }
                              return i;
                            }),
                          });
                        }}
                        type="number"
                        value={item?.value}
                      />
                    </div>
                  ))}
                </div>
                <div className="footer">
                  <button
                    disabled={!payload[type].some((item) => item.value)}
                    onClick={() => placeBet("")}
                    className="btn btn-primary"
                  >
                    Play
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Amount</th>
                  <th>Winning</th>
                </tr>
              </thead>
              <tbody>
                {myBets
                  ?.filter(
                    (item) =>
                      item?.betType == "ANDAR_HARUF" ||
                      item?.betType == "BAHAR_HARUF"
                  )
                  ?.map((item) =>
                    item?.betLockNumberAndAmountDTOList?.map((i) => {
                      return (
                        <tr>
                          <td>{i.number}</td>
                          <td>{i.amount}</td>
                          <td>{item.winning ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })
                  )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pointer">
                <div>
                  <p className="header">Points Remaining</p>
                  <p className="point-value">
                    {userData?.balance -
                      (payload[type].ANDAR.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0) +
                        payload[type].BAHAR.reduce((acc, item) => {
                          if (item.value) {
                            return acc + parseInt(item.value);
                          }
                          return acc;
                        }, 0))}
                  </p>
                </div>
                <div>
                  <p className="header">Points Added</p>
                  <p className="point-value">
                    {payload[type].ANDAR.reduce((acc, item) => {
                      if (item.value) {
                        return acc + parseInt(item.value);
                      }
                      return acc;
                    }, 0) +
                      payload[type].BAHAR.reduce((acc, item) => {
                        if (item.value) {
                          return acc + parseInt(item.value);
                        }
                        return acc;
                      }, 0)}
                  </p>
                </div>
              </div>
              <div className="haruf">
                <div className="main-content">
                  <h2>Andar Dhai</h2>
                  {payload[type]?.ANDAR.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10
                          ? "0" + item?.number
                          : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: {
                              ...payload[type],
                              ANDAR: payload[type].ANDAR.map((i) => {
                                if (i.number == item.number) {
                                  return {
                                    ...i,
                                    value: e.target.value,
                                  };
                                }
                                return i;
                              }),
                            },
                          });
                        }}
                        value={item?.value}
                        type="number"
                      />
                    </div>
                  ))}
                  <button
                    disabled={!payload[type].ANDAR.some((item) => item.value)}
                    onClick={() => placeBet("ANDAR_")}
                    className="btn btn-primary"
                  >
                    Play
                  </button>

                  <h2>Bahar Haruf</h2>
                  {payload[type]?.BAHAR.map((item) => (
                    <div className="divj">
                      <h1>
                        {item?.number < 10
                          ? "0" + item?.number
                          : item?.number}
                      </h1>
                      <input
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            [type]: {
                              ...payload[type],
                              BAHAR: payload[type].BAHAR.map((i) => {
                                if (i.number == item.number) {
                                  return {
                                    ...i,
                                    value: e.target.value,
                                  };
                                }
                                return i;
                              }),
                            },
                          });
                        }}
                        value={item?.value}
                        type="number"
                      />
                    </div>
                  ))}
                  <div className="footer">
                    <button
                      disabled={
                        !payload[type].BAHAR.some((item) => item.value)
                      }
                      onClick={() => placeBet("BAHAR_")}
                      className="btn btn-primary"
                    >
                      Play
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Amount</th>
                  <th>Winning</th>
                </tr>
              </thead>
              <tbody>
                {myBets
                  ?.filter(
                    (item) =>
                      item?.betType == "ANDAR_HARUF" ||
                      item?.betType == "BAHAR_HARUF"
                  )
                  ?.map((item) =>
                    item?.betLockNumberAndAmountDTOList?.map((i) => {
                      return (
                        <tr>
                          <td>{i.number}</td>
                          <td>{i.amount}</td>
                          <td>{item.winning ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })
                  )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
